import id_master_goods from "../../page/master/goods/language/id_master_goods"

const common = {
	action: "aksi",
	add: "tambah",
	address: "alamat",
	back: "kembali",
	batch: "tanda",
	category: "kategori",
	chose_img: "pilih gambar",
	cancel: "batal",
	courier: "kurir",
	company: "perusahaan",
	customer: "pelanggan",
	date: "tanggal",
	date_range: "rentang tanggal",
	date_update: "tanggal diperbarui",
	delete: "hapus",
	discount: "potongan",
	difference: "selisih",
	edit: "ubah",
	email: "E-mail",
	export_excel: "ekspor ke excel",
	export_csv: "ekspor ke CSV",
	export_pdf: "ekspor ke PDF",
	goods: "barang",
	history: "riwayat",
	image: "gambar",
	info: "keterangan",
	language: "bahasa",
	loading: "menunggu...",
	name: "nama",
	name_full: "nama lengkap",
	no: "Nomor",
	note: "catatan",
	no_option: "tidak ada pilihan",
	pass: "kata sandi",
	pass_conf: "konfirmasi kata sandi",
	pass_new: "kata sandi baru",
	pass_reset: "atur ulang kata sandi",
	pass_change: "ubah kata sandi",
	price: "harga",
	print: "cetak",
	profile: "profil",
	product: "produk",
	quantity: "kuantitas",
	reference: "referensi",
	request: "permintaan",
	save: "simpan data",
	search: "cari",
	serial_num: "nomor seri",
	supplier: "pemasok",
	status: "status",
	stock: "stok",
	telp: "telp",
	unit: "satuan",
	upload: "unggah",
	user: "pengguna",
	warehouse: "gudang",
}

const main_id_language = {
	translation: {
		common,
		crop_img: { crop: "crop", ok: "potong gambar" },
		menu: {
			dashboard: "beranda",
			warehouse: "cek gudang",
			whstrans: "transaksi gudang",
			master: "pengaturan",
			sales: "penjualan",
			ecommerce: "toko online",
			ecommregister: "pendaftaran 1Toko",
			ecommlist: "daftar toko online",
			purchase: "pembelian",
			supplychain: "rantai pasokan",
			supplychain_point: "titik distribusi",
			supplychain_request: "permintaan barang",
			report: "laporan",
			rka: "Perencanaan & Anggaran",
		},
		login: {
			title: "Masuk Akun",
			password: "Kata Sandi",
			forgotpass: "Lupa Kata Sandi ?",
			submit: "Kirim",
		},
		dashboard: {
			search: { title: "cari perusahaan", placeholder: "ID Perusahaan" },
		},
		warehouse: {},
		whstrans: {},
		master_goods: id_master_goods,
		master: {
			convertionadd: "tambah konversi barang",
			convertionedit: "ubah konversi barang",
			finance: "keuangan",
			goods: {
				add: "tambah barang",
				edit: "ubah barang",
				price: "harga barang",
				upload: "unggah barang",
				barcode: "cetak barcode",
				view: "detail barang",
			},
			supplier: {
				add: "tambah pemasok",
				edit: "ubah pemasok",
			},
			customer: {
				add: "tambah pelanggan",
				edit: "ubah pelanggan",
			},
			courier: {
				add: "tambah kurir",
				edit: "ubah kurir",
			},
			groupingadd: "tambah kelompok barang",
			groupingedit: "ubah kelompok barang",
			profileadd: "tambah profil",
			profileedit: "ubah profil",
			process: {
				"": "proses",
				add: "tambah proses",
				edit: "ubah proses",
			},
			storage: {
				room: "ruang",
				add: "tambah ruang penyimpanan",
				edit: "ubah ruang penyimpanan",
				model: "model ruang penyimpanan",
			},
			bankacc: {
				bank: "bank",
				norek: "account number",
				namerek: "account name",
				add: "tambah rekening bank",
				edit: "ubah rekening bank",
			},
			payment_meth: {
				"": "metode pembayaran",
				time: "lama (hari)",
				add: "tambah metode pembayaran",
				edit: "ubah metode pembayaran",
			},
			po_fee: {
				other_costs: "biaya lainnya",
				local: "Lokal",
				import: "Impor",
			},
			tnc: {
				"": "kebijakan dan ketentuan",
			},
			user: {
				"": "pengguna",
				add: "tambah pengguna",
				edit: "ubah pengguna",
			},
			whs_setting: {
				"": "pengaturan gudang",
				add: "tambah gudang",
				edit: "ubah gudang",
			},
		},
		mitra: {
			additemreq: "tambah permintaan barang",
			edititemreq: "ubah permintaan barang",
		},
		spal: {
			addspal: "tambah kontrak",
			editspal: "ubah kontrak",
			addpaymentmethod: "tambah cara pembayaran ( kontrak )",
			editpaymentmethod: "ubah cara pembayaran ( kontrak )",
			addtime: "tambah waktu bongkar & muat ( kontrak )",
			edittime: "ubah waktu bongkar & muat ( kontrak )",
			adddemurrage: "tambah denda keterlambatan  ( kontrak )",
			editdemurrage: "ubah denda keterlambatan ( kontrak )",
			form: {
				paymentmethod: "cara pembayaran",
				demurrage: "denda keterlambatan",
				time: "waktu bongkar & muat",
			},
		},
		supplychain: {
			"": "rantai pasokan",
			add: "tambah titik distribusi",
			edit: "ubah titik distribusi",
			addgoodsreq: "tambah permintaan barang",
			editgoodsreq: "ubah permintaan barang",
			detailgoodsreq: "detail permintaan barang",
		},
		transaction: {
			common: {
				whs: "gudang penyimpanan",
				search_ref: "cari referensi",
				trans_id: "ID transaksi",
				add_item: "tambah barang",
			},
			convertion: {
				"": "konversi",
				add: "tambah konversi",
			},
			initstock: {
				"": "stok awal",
			},
			itemin: {
				"": "barang masuk",
			},
			iteminlist: {
				"": "supervisi barang masuk",
				detail: "detail barang masuk",
			},
			itemout: {
				"": "barang keluar",
			},
			itemoutlist: {
				"": "supervisi barang keluar",
				detail: "detail barang keluar",
			},
			itemstock: {
				"": "stok barang",
			},
			itemmove: {
				"": "perpindahan barang",
			},
			itemprocess: {
				"": "proses barang",
			},
			stockopname: {
				"": "stock opname",
				add: "tambah stock opname",
				edit: "ubah stock opname",
				detail: "detail stok opname",
			},
		},
		sales: {
			returncustomer: {
				"": "retur pelanggan",
			},
		},
		purch: {
			returnsupplier: {
				"": "retur pemasok",
			},
			po: {
				"": "purchase order",
				create: "buat purchase order",
				edit: "ubah purchase order",
				create_mitra: "buat mitra purchase order",
				edit_mitra: "ubah mitra purchase order",
				currency: "mata uang",
				additional_cost: "tambahan biaya",
				additional_discount: "tambahan potongan",
				price_history: "riwayat harga",
				po_detail: "detail purchase order",
			},
		},
		report: {},
		rka: {
			"": "Perencanaan & Anggaran",
			planning: {
				"": "perencanaan",
				add: "buat rencana",
				edit: "ubah rencana",
			},
		},
	},
}

export default main_id_language
